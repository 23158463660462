import { inject, Injectable } from '@angular/core';
import { Product } from '@uc/web/shared/data-models';
import { DataLayerService } from '../data-layer/data-layer.service';
import {
	abbreviateProduct,
	isUserToNumber,
	verifyCleso,
	verifyUgso,
} from '../shared-functions/shared-functions';

@Injectable({ providedIn: 'root' })
export class CourseGTMService {
	private dataLayerSrv = inject(DataLayerService);

	courseProfileClick(
		uniId: number,
		uniName: string,
		courseName: string,
		courseId: number,
		ugSearchOrderPosition: number | null,
		cleSearchOrderPosition: number | null,
		product: Product | '' = '',
	) {
		const ugso = verifyUgso(ugSearchOrderPosition, product);
		const cleso = verifyCleso(cleSearchOrderPosition, product);
		const trackingData = Object.assign(
			{
				eventName: 'course_profile_click',
				university: uniId,
				universityName: uniName,
				course_name: courseName,
				product: abbreviateProduct(product),
				course_id: courseId,
				event: 'courseProfileClick',
			},
			ugso ? { ugso, cleso: 'n/a' } : {},
			cleso ? { cleso, ugso: 'n/a' } : {},
		);

		this.dataLayerSrv.push(trackingData);
	}

	keywordImpression(
		componentName: string,
		uniId: number,
		uniName: string,
		isUser: boolean,
		subject: string,
		product: Product | '' = '',
	) {
		this.dataLayerSrv.push({
			eventName: 'keyword_impression',
			componentName: componentName,
			university: uniId,
			universityName: uniName,
			product: abbreviateProduct(product),
			user: isUserToNumber(isUser),
			subject_name: subject,
			event: 'keywordImpression',
		});
	}

	keywordClick(
		componentName: string,
		uniId: number,
		uniName: string,
		isUser: boolean,
		subject: string,
		product: Product | '' = '',
	) {
		this.dataLayerSrv.push({
			eventName: 'keyword_click',
			componentName: componentName,
			university: uniId,
			universityName: uniName,
			product: abbreviateProduct(product),
			user: isUserToNumber(isUser),
			subject_name: subject,
			event: 'keywordClick',
		});
	}

	courseImpression(
		uniId: number,
		uniName: string,
		courseName: string,
		courseId: number,
		ugSearchOrderPosition: number | null,
		cleSearchOrderPosition: number | null,
		product: Product | '' = '',
	) {
		const ugso = verifyUgso(ugSearchOrderPosition, product);
		const cleso = verifyCleso(cleSearchOrderPosition, product);
		const trackingData = Object.assign(
			{
				eventName: 'course_impression',
				university: uniId,
				universityName: uniName,
				product: abbreviateProduct(product),
				course_name: courseName,
				course_id: courseId,
				event: 'courseImpression',
			},
			ugso ? { ugso, cleso: 'n/a' } : {},
			cleso ? { cleso, ugso: 'n/a' } : {},
		);

		this.dataLayerSrv.push(trackingData);
	}

	/*
  		User views the screen for a course profile
	*/
	courseProfileView(
		uniId: number | undefined,
		uniName: string,
		courseName: string,
		courseId: number,
		ugSearchOrderPosition: number | null,
		cleSearchOrderPosition: number | null,
		product: Product | '' = '',
	) {
		const ugso = verifyUgso(ugSearchOrderPosition, product);
		const cleso = verifyCleso(cleSearchOrderPosition, product);
		const trackingData = Object.assign(
			{
				eventName: 'course_profile_view',
				university: uniId,
				universityName: uniName,
				product: abbreviateProduct(product),
				course_name: courseName,
				course_id: courseId,
				event: 'courseProfileView',
			},
			ugso ? { ugso, cleso: 'n/a' } : {},
			cleso ? { cleso, ugso: 'n/a' } : {},
		);

		this.dataLayerSrv.push(trackingData);
	}

	/**
	 * When user clicks search courses button in statements header
	 */

	searchCourseClick() {
		this.dataLayerSrv.push({
			eventName: 'search_course_click',
			event: 'searchCourseClick',
		});
	}

	/**
	 * When user clicks view more courses button
	 */

	viewMore(uniId: number, uniName: string, searchTerm: string) {
		this.dataLayerSrv.push({
			eventName: 'see_more_courses_button_click',
			university: uniId,
			universityName: uniName,
			searchQuery: searchTerm,
			event: 'viewMore',
		});
	}
}

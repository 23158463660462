import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import {
	catchError,
	concatMap,
	distinctUntilChanged,
	forkJoin,
	map,
	of,
	switchMap,
	tap,
	withLatestFrom,
} from 'rxjs';
import {
	fetchClientDetails,
	fetchRegionsOptions,
	fetchStudentDetails,
	fetchStudentFavourites,
	loadUserDetailsError,
	updateClientDetails,
	updateRegionOptions,
	updateStudentApplicationDetails,
	updateStudentDetails,
	updateStudentFavourites,
	updateStudentPersonalDetails,
} from './user-details.actions';
import { UserDetailsApiService } from '../user-details-api.service';
import { AuthenticationService } from '@uc/shared/authentication';
import { Store } from '@ngrx/store';
import { StudentDetails } from '@uc/web/shared/data-models';
import { getRegionOptions, getStudentDetailsData } from './user-details.selectors';
import { FormOptionsApiService } from '../../form-options/form-options-api.service';

@Injectable()
export class StateUserDetailsEffect {
	constructor(
		private readonly _actions$: Actions,
		private _store: Store,
		private _userDetailsApiSrv: UserDetailsApiService,
		private _authSrv: AuthenticationService,
		private _formOptionsApiSrv: FormOptionsApiService,
	) {}

	fetchUserDetails = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchStudentDetails),
			distinctUntilChanged(),
			switchMap(() => this._userDetailsApiSrv.getStudentDetails()),
			map((payload) =>
				updateStudentDetails({
					student: payload,
				}),
			),
			catchError((error) => {
				if (error.status === 401) {
					this._authSrv.signOut();
				}
				return of(loadUserDetailsError({ error }));
			}),
		),
	);

	updateUserPersonalDetails = createEffect(() =>
		this._actions$.pipe(
			ofType(updateStudentPersonalDetails),
			concatLatestFrom(() => [
				this._store.select(getStudentDetailsData),
				this._store.select(getRegionOptions),
			]),
			concatMap(([{ student }, oldDetails, regions]) =>
				this._userDetailsApiSrv.changeAccountDetails(student).pipe(
					map(() => {
						const regionId = regions?.find(
							(region: { name: string | undefined }) =>
								region.name === student.address.region,
						)?.id;

						const userDetails = {
							...oldDetails,
							first_name: student.first_name,
							last_name: student.last_name,
							phone_number: student.phone_number,
							country_code: student.country_code,
							address: {
								country: student.address.country,
								street: student.address.street,
								city: student.address.city,
								postcode: student.address.postcode ?? '',
								region_id: regionId,
							},
						} as StudentDetails;
						return updateStudentDetails({
							student: userDetails,
						});
					}),
					catchError((error) => {
						const errorMsgs = this._getErrorMessage(error);
						return of(loadUserDetailsError({ error: errorMsgs }));
					}),
				),
			),
		),
	);

	updateStudentApplicationDetails = createEffect(() =>
		this._actions$.pipe(
			ofType(updateStudentApplicationDetails),
			withLatestFrom(this._store.select(getStudentDetailsData)),
			concatMap(([{ student }, oldDetails]) =>
				this._userDetailsApiSrv.changeApplicationDetails(student).pipe(
					map(() => {
						const userDetails = {
							...oldDetails,
							preferences: {
								subject: student.degree.subject,
								qualification: student.degree.level,
								start_year: student.degree.start_year,
							},
						} as StudentDetails;

						return updateStudentDetails({
							student: userDetails,
						});
					}),
					catchError((error) => {
						const errorMsgs = this._getErrorMessage(error);
						return of(loadUserDetailsError({ error: errorMsgs }));
					}),
				),
			),
		),
	);

	fetchStudentFavourites = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchStudentFavourites),
			distinctUntilChanged(),
			switchMap(() =>
				forkJoin([
					this._userDetailsApiSrv.getStudentFavouriteUnis(),
					this._userDetailsApiSrv.getStudentFavouriteCourses(),
				]),
			),
			map((response) =>
				updateStudentFavourites({
					universities: response[0],
					courses: response[1],
				}),
			),
			catchError(async (error) => loadUserDetailsError({ error })),
		),
	);

	fetchClientDetails = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchClientDetails),
			distinctUntilChanged(),
			switchMap(() => this._userDetailsApiSrv.getClientDetails()),
			map((payload) =>
				updateClientDetails({
					client: payload,
				}),
			),
			catchError((error) => {
				if (error.status === 401) {
					this._authSrv.signOut();
				}
				return of(loadUserDetailsError({ error }));
			}),
		),
	);

	fetchRegionsOptions = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchRegionsOptions),
			withLatestFrom(this._store.select(getStudentDetailsData)),
			switchMap(([payload]) => {
				return this._formOptionsApiSrv.getRegions(payload.country.id).pipe(
					map((regions) => {
						return updateRegionOptions({ regions });
					}),
					catchError(() =>
						of(
							loadUserDetailsError({
								error: 'An unknown error occurred. Please try again',
							}),
						),
					),
				);
			}),
		),
	);

	private _getErrorMessage(error: any) {
		if (error?.error?.errors) {
			return error.error.errors;
		} else if (error?.error?.message) {
			return { phone_number: [error.error.message] };
		} else {
			return { generic: ['An unknown error occurred. Please try again later.'] };
		}
	}
}
